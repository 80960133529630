* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #022647;
  min-height: 100vh;
  flex-direction: column;
  font-family: Barlow Semi Condensed, sans-serif;
  font-weight: 400;
  line-height: 1;
  display: flex;
  overflow-x: hidden;
}

.container {
  max-width: 90vw;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  gap: 9.6rem 6.4rem;
  margin-bottom: 9.6rem;
  display: grid;
}

.flex {
  gap: 6.4rem;
  display: flex;
}

.grid:last-child {
  margin-bottom: 0;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.heading-primary, .heading-secondary, .heading-tertiary {
  letter-spacing: -.5px;
  font-weight: 700;
}

.heading-primary {
  margin-bottom: 3.2rem;
  font-size: 5.2rem;
  line-height: 1.05;
}

.heading-secondary {
  margin-bottom: 9.6rem;
  font-size: 4.4rem;
  line-height: 1.2;
}

.heading-tertiary {
  margin-bottom: 3.2rem;
  font-size: 3rem;
  line-height: 1.2;
}

.section-header {
  margin-bottom: 8rem;
  padding: 2.4rem 0 0 4.8rem;
  font-size: 3.6rem;
  line-height: 1.2;
}

.subheading {
  color: #91897b;
  text-transform: uppercase;
  letter-spacing: .75px;
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
}

.btn, .btn:link, .btn:visited {
  color: #fff;
  border-radius: 9px;
  padding: 1.6rem 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.btn--full:link, .btn--ful:visited {
  background-color: #e67e22;
}

.btn--full:hover, .btn--ful:active {
  background-color: #cf711f;
}

.btn--outline:link, .btn--outline:visited {
  color: #3d3d3d;
  background-color: #fff;
}

.btn--outline:hover, .btn--outline:active {
  background-color: #fdf2e9;
  box-shadow: inset 0 0 0 3px #fff;
}

.link:link, .link:visited {
  color: #e67e22;
  border-bottom: 1px solid;
  padding-bottom: 2px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.link:hover, .link:active {
  color: #cf711f;
  border-bottom: 1px solid #0000;
}

.list {
  flex-direction: column;
  gap: 1.6rem;
  list-style: none;
  display: flex;
}

.list-item {
  align-items: center;
  gap: 1.6rem;
  font-size: 1.8rem;
  display: flex;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: #e67e22;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.center-text {
  text-align: center;
}

strong {
  font-weight: 500;
}

.indent {
  padding-left: 2.4rem;
}

/*# sourceMappingURL=index.cddf948d.css.map */
