/* 

-----01 Typography System 

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

FONT WEIGHTS: 
Default: 400
Medium: 500
Semi-bold: 600
Bold: 700

LINE HEIGHTS:
Default: 1 
Small: 1.05
Medium: 1.2
Paragraph default: 1.6

LETTER SPACING:
-.5px
.75px

--------------------------------------------------------------------
Colors
- Primary: 
#022647
#fdfcfb

- Tints: 

- Shades: 
- Accents:
- Greys: 



---------------------------------------------

Shadows

--------------------------------------

Border-Radius

Default: 9px
11px

-----------------------------------------

Whitespace

SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
  /* outline: 4px dotted #797367;
    outline-offset: 8px; */
  /* box-shadow: 0 0 0 0.8rem rgb(121, 115, 103, 0.5); */
}

html {
  /* font-size: 10px; */
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Barlow Semi Condensed", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #022647;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* ********************************* */
/* GENERAL REUSABLE COMPONENTS */
/* ********************************* */

.container {
  max-width: 90vw;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  row-gap: 9.6rem;
  column-gap: 6.4rem;
  margin-bottom: 9.6rem;
}

.flex {
  display: flex;
  gap: 6.4rem;
}

.grid:last-child {
  margin-bottom: 0;
}

/* .grid:not(:last-child) {
    margin-bottom: 9.6rem;
  } */

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}
.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--center-v {
  align-items: center;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  letter-spacing: -0.5px;
  font-weight: 700;
}

.heading-primary {
  font-size: 5.2rem;

  line-height: 1.05;

  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 9.6rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.section-header {
  font-size: 3.6rem;
  line-height: 1.2;
  padding: 2.4rem 0 0 4.8rem;
  margin-bottom: 8rem;
}

.subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #91897b;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.btn,
.btn:link,
.btn:visited {
  display: inline-block;

  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;

  padding: 1.6rem 3.2rem;
  border-radius: 9px;

  transition: all 0.3s;
  color: #fff;
}

.btn--full:link,
.btn--ful:visited {
  background-color: #e67e22;
}

.btn--full:hover,
.btn--ful:active {
  background-color: #cf711f;
}

.btn--outline:link,
.btn--outline:visited {
  background-color: #fff;
  color: rgb(61, 61, 61);
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #fdf2e9;
  /* border: 3px solid #fff; Doesn't work makes page jump*/
  box-shadow: inset 0 0 0 3px #fff;
}

.link:link,
.link:visited {
  display: inline-block;
  color: #e67e22;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  transition: all 0.3s;
}

.link:hover,
.link:active {
  color: #cf711f;
  border-bottom: 1px solid transparent;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.list-item {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.list-icon {
  width: 3rem;
  height: 3rem;
  color: #e67e22;
}
/* ********************************** */
/* HELPER CLASSES */

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.center-text {
  text-align: center;
}

strong {
  font-weight: 500;
}

.indent {
  padding-left: 2.4rem;
}
